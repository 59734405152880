.Game {
    --primary-color: #d7afff;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1f1f1f;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
}

.Game__TurnsCount {
    position: absolute;
    top: 0;
    left: 0;
}

.Game__Map {
    flex: 1;
}

.Game__Players {
    position: absolute;
    top: 0;
    right: 0;
}
